import $ from 'jquery';

export function contsTabs() {
    //タブ切り替え
	function switchTab(index) {
		$(".tabs--item.is_active").removeClass("is_active");
		$(".tabs--item").eq(index).addClass("is_active");
        if($(".conts").eq(index).length >= 1 ){
            $(".conts.is_show").removeClass("is_show");
            $(".conts").eq(index).addClass("is_show");
        }
	}

	// URLのパラメータを取得
	var urlParam = location.search.substring(1);

	// URLにパラメータが存在する場合
	if(urlParam) {
		// 「&」が含まれている場合は「&」で分割
		var param = urlParam.split('&');

		// パラメータを格納する用の配列を用意
		var paramArray = [];

		// 用意した配列にパラメータを格納
		for (var i = 0; i < param.length; i++) {
			var paramItem = param[i].split('=');
			paramArray[paramItem[0]] = paramItem[1];
		}
		var index = paramArray.category - 1;
	} else {
		var index = 0;
	}
	switchTab(index);

    $(".tabs .tabs--item").on("click",function(){
		var index = $(this).index();//インデックス番号を取得
		switchTab(index);
	});
}
