// =====================================================================================
//
// エントリーポイント / メイン処理
//
// =====================================================================================

import $ from 'jquery';

import { slideMenu } from './modules/slideMenu';
import { pageTop } from './modules/pageTop';
import { contsTabs } from './modules/contsTabs';
import { contsNarrow } from './modules/contsNarrow';
import { contsDisplay } from './modules/contsDisplay';
import { contsMore } from './modules/contsMore';
import { favoriteBtn } from './modules/favoriteBtn';
import { search_tag } from "./modules/search_tag";
import { search_acodion } from './modules/search_acodion';
import { search_insert } from './modules/search_insert';

slideMenu(); //ハンバーガーメニューによるスライド
pageTop(); //トップに戻る
// favoriteBtn(); //お気に入りボタン処理
contsTabs(); //タブ切り替え
contsNarrow(); //絞り込み切り替え
contsDisplay(); // グリッド/ 詳細表示切り替え
contsMore(); //もっと見る
//search_tag(); //フォームタグボタンの制御
search_acodion(); //アコーディオン
//search_insert(); //SP版フォームのチェックしたらテキスト挿入

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

$(()=>{
});
