import $ from 'jquery';

export function contsDisplay() {
    // グリッド/ 詳細表示切り替え
	$('.conts--display_item a').on('click', function(){
		var list = $('.list');
		$(this).parent().addClass('is_active');
		$(this).parent().siblings().removeClass('is_active');

		if (list.hasClass('is_grid')) {
			list.removeClass('is_grid').addClass('is_detail');
		} else {
			list.removeClass('is_detail').addClass('is_grid');
		}
	});
}