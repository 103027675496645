import $ from 'jquery';

export function pageTop(){
	var pagetop = $('.footer--gotop');

	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 500) {
			pagetop.addClass('is-show');
		} else {
			pagetop.removeClass('is-show');
		}
	});

	pagetop.on('click', function() {
		$("html,body").animate({ scrollTop: 0 }, 500);
	});
}