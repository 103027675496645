import $ from 'jquery';

export function search_acodion(){
	if (window.matchMedia( "(max-width: 768px)" ).matches) {
		
		$('.aco').on('click', function(){
			var acobtn = $(this);
			acobtn.next().stop(false, true).slideToggle(200, function(){
				if(acobtn.hasClass('is-active')){
					acobtn.removeClass('is-active');
				}else{
					acobtn.addClass('is-active');
				}
			});
		});
	} 
}