import $ from 'jquery';

export function contsMore() {
    //もっと見る
	$(document).on('click', '.conts--more', function() {
		var hideList = $(this).parent().find(".list--list.is_hide");
		var length = hideList.length;
		hideList.first().removeClass('is_hide').addClass('is_active');
		length = length - 1;
		
		if (length == 0) {
			$(this).addClass('is_full');
		}
	});

	$(document).on('click', '.conts--more.is_full', function() {
		var hideList = $(this).parent().find(".list--list.is_active");
		$(this).removeClass('is_full');
		hideList.removeClass('is_active').addClass('is_hide');
	});
}